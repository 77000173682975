import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"
import './App.scss'
import Contract from './Contract'

export default class App extends Component {
  render () {
    return (
      <Router>
        <div className='App'>
          <Route path="/:id?" component={Contract} />
        </div>
      </Router>
    )
  }
}
