import React, { Component } from 'react'
import Bookmark from 'react-bookmark'
import { ReactComponent as Border } from './assets/border.svg'
import { ReactComponent as Print } from './assets/print.svg'
import { ReactComponent as Save } from './assets/save.svg'
import classNames from 'classnames'
import moment from 'moment'
import './Contract.scss'
import Intro from './Intro'
import Header from './Header'

export default class Contract extends Component {

  constructor (props) {
    super(props)
    const now = moment()
    this.state = {
      info: {
        day: now.format('Do'),
        month: now.format('MMMM'),
        year: now.format('YY'),
        artist: '',
        artistAddr: '',
        collector: '',
        collectorAddr: '',
        workTitle: '',
        workId: '',
        workDate: '',
        workMaterial: '',
        workDims: '',
        workDesc: '',
        price: '',
        value: '',
        agent: '',
        agentAddr: '',
        charity: '',
        charityAddr: '',
        expiration: '',
        artistSig: '',
        collectorSig: ''
      }
    }
    // this.base = /localhost/.test(window.location.href) ? 'http://localhost:3000/' : 'https://kadist-agreement-owise1.vercel.app/'
    // this.base = 'https://kadist-agreement.owise1.now.sh/'
    this.base = 'https://kadist-agreement-owise1.vercel.app/'
  }
  componentDidMount () {
    if (this.props.match.params.id) {
      this._fetch(this.props.match.params.id)
    }
  }
  _fetch (id) {
    this.setState({
      fetching: true
    })
    fetch(this.base + 'api/contract?c=' + id)
      .then(response => response.json())
      .then(res => {
        this.setState({
          info: res.data.info,
          fetching: false,
          fetched: true
        })
      })
  }
  _save () {
    this.setState({ saving: true })
    fetch(this.base + 'api/contract', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ info: this.state.info })
    })
      .then(response => response.json())
      .then(res => {
        window.location.href = window.location.protocol + '//' + window.location.host + '/' + res._id
      })
  }
  render () {
    const { fetched, info, fetching, saving } = this.state
    const field = (prop) => {
      return <input
        onChange={(e) => {
          info[prop] = e.target.value
          this.setState({ info })
        }}
        className={classNames('field', prop)}
        value={info[prop]}
        type='text'
      />
    }
    let finished, allSet, bottom
    if (fetched) {
      allSet = <a className='btn' onClick={window.print}><Print /></a>
    }
    if (fetching || fetched) {
      finished = <div className="finished">
        <Border id="border" />
        <div className="message">
          <p>Below is a saved agreement:</p>
          <p>
            <span className='artist'><span className="by">by: </span>{info.artist}</span>
            <span className='work'><span className="by">re: </span>{info.workTitle}</span>
          </p>
        <p className="nb">
          Bookmark this URL to save for future reference,<br/>Scroll down to Revise & Re-save this contract
          <br/>or
        </p>
        {allSet}
        <p className='created'>Created on {info.month} {info.day}, 20{info.year}</p>
        </div>
      </div>
      bottom = <div className='bottom'>
        <h4>Transfer Agreement And Record</h4>
        <p className="nb">
          To:
        </p>
        <p className="full nb">
          Know ye that <input type="text" />
        </p>
        <p className="full nb">
          residing at <input type="text" />
        </p>
        <p>
          has this day transferred all his right, title and interest in that certain Work of art known as:
          <br/>Title: {info.workTitle}
          <br/>Identification #: {info.workId}
          <br/>Date: {info.month} {info.day}, 20{info.year}
          <br/>Material: {info.workMaterial}
          <br/>Dimensions: {info.workDims}
          <br/>Description: {info.workDesc}
        </p>
        <p>
          to <input type="text" /> residing at <input type="text" />, transferee, at the agreed price or value of <input type="text" />. Transferee, hereby expressly ratifies and affirms all the terms of that cetain Agreement made by and between <input type="text" /> and <input type="text" /> on the <input className="day" type="text" /> day of <input type="text" className="month" />, 20<input type="text" className="year" />, and agrees to be bound thereby and to perform and fulfill all of Collector's covenants set forth in said agreement.
        </p>
        <p>
          Done this <input className="day" type="text" /> day of <input className="month" type="text" />, 20<input type="text" className="year" />, at <input type="text" />
        </p>

      </div>
    }
    if (fetching) {
      return (
        <div className="contract-component fetching" >{finished} Fetching your contract...</div>
      )
    }
    return (
      <div className="contract-wrap">
        {fetching || fetched ? null : <div><Header/><Intro /></div>}
        <div className='contract-component'>
          {finished}
        <p>
        This agreement made this {field('day')} day of {field('month')}, 20{field('year')},<br/>by and between {field('artist')} (hereinafter the “Artist”),<br/>residing at {field('artistAddr')}<br/>and {field('collector')} (the “Collector”),<br/>residing at {field('collectorAddr')};
          </p>
          <p>
            WITNESSETH:
          </p>
          <p className="nb">
            WHEREAS the Artist has created that certain work of art;
          </p>
          <p className="full">
            <span>Title:</span> {field('workTitle')}
          </p>
          <p className="full">
                Identification #: {field('workId')}
              </p>
          <p className="full">
                Date: {field('workDate')}
              </p>
          <p className="full">
              Material: {field('workMaterial')}
              </p>
          <p className="full">
              Dimensions: {field('workDims')}
          </p>
          <p className="full">
          Description: {field('workDesc')}
          </p>
          <p>
          (hereinafter “the Work”); and
          </p>
          <p>
          WHEREAS The Work is being sold to Collector and Collector is willing to purchase the Work, subject to mutual obligations, covenants, and conditions herein; and
          </p>
          <p>
          WHEREAS Collector and Artist recognize that the value of the Work, unlike that of any ordinary chattel, is and will be affected by each and every other work of art the Artist has created and will hereafter create; and
          </p>
          <p>
          WHEREAS the parties expect the value of the Work to increase hereafter; and
          </p>
          <p>
          WHEREAS Collector and Artist recognize that it is fitting and proper that Artist participate in any appreciated value which may thus be created in the Work; and
          </p>
          <p>
          NOW, THEREFORE, in consideration of the foregoing premises and the mutual covenants hereinafter set forth and other valuable considerations the parties hereto agree as follows:
          </p>
          <p>
          <b>PURCHASE AND SALE.</b> <br/>ARTICLE ONE:  The Collector is purchasing the Work subject to all the covenants herein set forth (for the price of {field('price')}) (at the agreed valuation for the purposes of this agreement of {field('value')}).
              </p>
          <p className="nb">
          <b>FUTURE TRANSFERS</b>.  <br/>ARTICLE TWO:  Collector covenants that in the event Collector shall hereafter sell, give, grant, barter, exchange, assign, transfer, convey or alienate the Work in any manner whatsoever or if the Work shall pass by inheritance or bequest or by operation of law, or if the Work shall be destroyed and insurance proceeds paid therefor, Collector or Collector’s personal representative shall:
                                </p>
          <p className="indent nb">
          (a)  file a current TRANSFER AGREEMENT AND RECORD in the form and containing the information set forth and called for in the specimen hereunto annexed and made a part hereof, completed and dated, and subscribed by Collector or Collector’s personal representative and Collector’s transferee, with the (Artist at the address set forth above)  (Artist’s agent for the purpose: {field('agent')} 
            at: {field('agentAddr')}) within thirty days of such transfer, distribution, or payment of insurance proceeds, and shall
          </p>
          <p className="indent">
          (b)  pay a sum equal to fifteen percent (15%) of the Appreciated Value (as hereinafter defined), if any, occasioned by such transfer or distribution or payment of insurance proceeds to<br/>{field('charity')} (charitable organization),<br/>located at {field('charityAddr')}, or such other charitable organization as might be later designated by Artist.
          </p>
          <p className="nb">
          <b>PRICE/VALUE.</b><br/> ARTICLE THREE:  The “price or value” to be entered on a TRANSFER AGREEMENT AND RECORD shall be:
                                          </p>
          <p className="indent nb">
          (a)  the actual selling price if the Work is sold for money; or
          </p>
          <p className="indent nb">
          (b)  the money value of the consideration if the Work is bartered or exchanged for a valuable consideration; or
          </p>
          <p className="indent">
          (c)  the fair market value of the Work if it is transferred in any other manner.
                                                                                </p>
          <p className="nb">
          <b>APPRECIATED VALUE.</b><br/> ARTICLE FOUR:  “Appreciated Value” of the Work, for the purposes of this Agreement, shall be the increase, if any, in the value or price of the Work set forth in a current duly executed and filed TRANSFER AGREEMENT AND RECORD over the price or value set forth in the last prior duly executed and filed TRANSFER AGREEMENT AND RECORD, or, if there be no prior duly executed and filed TRANSFER AGREEMENT AND RECORD, over the price or value set forth in ARTICLE ONE herein.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </p>
          <p className="indent">
          (a) In the event a current duly executed TRANSFER AGREEMENT AND RECORD is not timely filed as required by ARTICLE TWO herein, Appreciated Value shall nonetheless be computed as if such current TRANSFER AGREEMENT AND RECORD had been duly executed and filed, with a price or value set forth therein equal to the actual market value of the Work at the time of the current transfer or at the time of the discovery of such transfer.
                                                                                                                                                                                                                                                                                                                                                                                                                                            </p>
          <p>
          <b>TRANSFEREES TO RATIFY AGREEMENT</b>. <br/> ARTICLE FIVE:  Collector hereby covenants not to hereafter, sell, give, grant, barter, exchange, assign, transfer, convey or alienate the Work in any manner whatsoever or permit the Work to pass by inheritance or bequest or by operation of law to any person without procuring such transferee’s ratification and affirmation of all the terms of this Agreement and transferee’s agreement to be bound hereby and to perform and fulfill all the Collector’s covenants set forth herein, said ratification, affirmation and agreement to be evidenced by such transferee’s subscription of a current duly completed and filed TRANSFER AGREEMENT AND RECORD.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </p>
          <p>
          <b>TRANSFEREES BOUND</b>.  <br/>ARTICLE SIX:  In the event the Work shall hereafter be transferred or otherwise alienated from Collector or Collector’s estate in any manner whatsoever, any transferee taking the Work with notice of this Agreement shall in every respect be bound and liable to perform and fulfill each and every covenant herein as if such transferee had duly made and subscribed a properly executed TRANSFER AGREEMENT AND RECORD in accordance with ARTICLE TWO and ARTICLE FIVE herein at the time the Work was transferred to him or her.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  </p>
          <p>
          <b>EXPIRATION</b>.  <br/>ARTICLE SEVEN:  This agreement and the covenants herein shall be binding upon the parties, their heirs, legatees, executors, administrators, assigns, transferees and all other successors in interest and the Collector’s covenants do attach and run with the Work and shall be binding to and until {field('expiration')} years after the deaths of Artist and Artist’s surviving spouse, if any.
                                                                                                                                                                                                                                                                                                                                                                                                                        </p>
          <p>
          <b>WAIVERS NOT CONTINUING</b>.  <br/>ARTICLE EIGHT:  Any waiver by either party of any provision of this Agreement, or of any right hereunder, shall not be deemed a continuing waiver and shall not prevent or estop such party from thereafter enforcing such provision or right, and the failure of either party to insist in any one or more instances upon the strict performance of any of the terms or provisions of this Agreement by the other party shall not be construed as a waiver or relinquishment for the future of any such terms or provisions, but the same shall continue in full force and effect.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </p>
          <p>
          <b>AMENDMENT IN WRITING</b>.  <br/>ARTICLE NINE:  This Agreement shall not be subject to amendment, modification, or termination, except in writing signed by both parties.
                                                                                                                                                                      </p>
          <p>
          <b>ATTORNEYS’ FEES</b>.  <br/>ARTICLE TEN:  In the event that either party shall hereafter bring any action upon any default in performance or observance of any covenant herein, the party aggrieved may recover reasonable attorneys’ fees in addition to whatever remedies may be available to him or her.
                                                                                                                                                                                                                                                                                                        </p>
          <p>
          IN WITNESS WHEREOF, the parties have set their hands and seals to this Agreement as of the day and year first above written.
                                                                                                                            </p>
          <p>
            {field('artistSig')}
            <span className="printed">
              <span className="name">{info.artistSig}</span>
              (Artist)
            </span>
          </p>
          <p>
            {field('collectorSig')}
            <span className="printed">
              <span className="name">{info.collectorSig}</span>
              (Collector)
            </span>
          </p>
          <button className="save btn" disabled={saving} onClick={this._save.bind(this)}>{saving ? 'One sec...' : <Save />}</button>
          {bottom}
        </div>
      </div>
    )
  }
}
