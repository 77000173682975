import React, { Component } from 'react'
import { parse, stringify } from 'himalaya'
import "./Intro.scss"
import { ReactComponent as Button } from './assets/read-more.svg'
import Modal from 'react-modal'


export default class Intro extends Component {
  state = {
    show: 1
  }
  render () {
    const { show } = this.state
    const introElements = parse(document.getElementById('intro').innerHTML).filter(el => el.type !== 'text')
    let __html = stringify(introElements.slice(0, show))
    const closeModal = () => this.setState({ show: 1 })
    let btn = <button
      className="btn"
      onClick={() => {
        if (show === 1) {
          this.setState({ show: 3 })
        } else {
          this.setState({ show: 10000 })
        }
      }}><Button /></button>
    if (show > 3) btn = ''

    return (
      <div className="intro-component">
        <div className="intro" dangerouslySetInnerHTML={{ __html }} />
        {btn}
        <Modal
          isOpen={show > 3}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
          onAfterOpen={() => {
            const modal = document.getElementsByClassName('Modal')[0]
            const what = modal.querySelector('.what')
            modal.scrollTo(0, what.offsetTop - 15)
            modal.querySelectorAll('sup').forEach(elem => {
              elem.addEventListener('click', () => {
                const what = modal.querySelector('#f' + elem.textContent)
                modal.scrollTo(0, what.offsetTop - 15)
                console.log(elem.textContent)
              })
            })
          }}
        >
          <a href="#" onClick={closeModal}  className="close menu"><div className="burger-icon"></div></a>
          <div className="intro" dangerouslySetInnerHTML={{ __html: stringify(introElements) }} />
        </Modal>
      </div>
    )
  }
}
