import React, { Component } from 'react'
import { ReactComponent as Title } from './assets/title-text.svg'
import { ReactComponent as Border } from './assets/border.svg'


export default class Header extends Component {
  state = {}
  _onMouseMove(e) {
    this.setState({ x: e.nativeEvent.offsetX });
  }
  render () {
    const { x } = this.state
    const fill = x ? 'url(#grad1)' : '#000'
    const colors = [
      'rgb(255, 255, 0)',
      '#e91baf',
      'rgb(255, 0, 0)'
    ]
    /* const percent = (x ? (x / 20) % 100 : 0) + '%' 
     */
    const percent = 0
    return (
      <div onMouseMove={this._onMouseMove.bind(this)} onMouseLeave={() => this.setState({ x: false })}  id="title">
        <svg fill={fill}>
          <Border />
        </svg>
        <Title id="text" />
      </div>
    )
  }
}
